import React from "react";
import styled from "styled-components";
import Link from "next/link";

interface IWGameItem {
  title: string;
  imageSrc: string;
  link: string;
}

const WgamesList: IWGameItem[] = [
  {
    title: "plinko",
    imageSrc: "/images/gamesComponent/plinko.png",
    link: "/games/plinko",
  },
  {
    title: "mines",
    imageSrc: "/images/gamesComponent/mines.png",
    link: "/games/mines",
  },
  {
    title: "roulette",
    imageSrc: "/images/gamesComponent/roulette.png",
    link: "/games/roulette",
  },
  {
    title: "blackjack",
    imageSrc: "/images/gamesComponent/blackjack.png",
    link: "/games/blackjack",
  },
  {
    title: "crash",
    imageSrc: "/images/gamesComponent/crash.png",
    link: "/games/crash",
  },
];

export interface IWGamesComponent {
  id?: string;
}

export const WGamesOriginItems = () => {
  return (
    <>
      {WgamesList.map((item, index) => {
        return (
          <WGameCard key={index} href={item.link} img={item.imageSrc}>
            <WGameTitle>
              {item.title}
              <span>w Originals</span>
            </WGameTitle>
          </WGameCard>
        );
      })}
    </>
  );
};

export const WGamesComponent: React.FC<IWGamesComponent> = ({ id }): JSX.Element => {
  return (
    <>
      <Box id={id} className="container">
        <SectionWGamesGrid>
          {WgamesList.map((item, index) => {
            return (
              <WGameCard key={index} href={item.link} img={item.imageSrc}>
                <WGameTitle>
                  {item.title}
                  <span>w Originals</span>
                </WGameTitle>
              </WGameCard>
            );
          })}
        </SectionWGamesGrid>
      </Box>
    </>
  );
};

const Box = styled.div`
  padding: 16px;
`;

const SectionWGamesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(168px, 200px));
  place-content: center;
  gap: 16px;

  @media (max-width: 939px) {
    grid-template-columns: repeat(5, 1fr);
  }

  @media (max-width: 767px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: 670px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 500px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const WGameTitle = styled.div`
  display: flex;
  flex-direction: column;
  color: #ffffff;
  text-align: center;
  font-family: var(--font-family-exo);
  font-size: 22px;
  font-style: italic;
  font-weight: 700;
  letter-spacing: 0.3px;
  opacity: 0.9;
  text-transform: uppercase;
  gap: 10px;
  transition: gap 0.3s, font-size 0.3s;

  span {
    font-size: 10px;
    opacity: 0.75;
  }

  @media (max-width: 939px) {
    font-size: 20px;
    gap: 5px;
  }
`;

const WGameCard = styled(Link)<{ img: string }>`
  overflow: hidden;
  display: flex;
  align-items: end;
  justify-content: center;
  flex: 1;
  position: relative;
  border-radius: 12px;
  padding: 16px;
  transition: padding 0.3s;
  aspect-ratio: 3/4;

  &:before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(${(props) => props.img}) no-repeat center top / cover;
    transform: scale(1);
    transition: transform 0.3s;
  }

  &:hover {
    padding: 10px 16px;

    &:before {
      transform: scale(1.05);
    }

    ${WGameTitle} {
      gap: 0;
      font-size: 26px;

      @media (max-width: 939px) {
        font-size: 22px;
      }
    }
  }
`;
