import { withSuperJSONPage as _withSuperJSONPage } from "babel-plugin-superjson-next/tools";
var __jsx = React.createElement;
import React from "react";
import { FrequentlyAskedQuestionsComponent
// HeroComponent,
// LeaderboardWinnersScreen,
// PachinkoComponent,
// PointShopComponent,
// RafflesComponent,
// YoutubeFeedComponent,
, SlotsComponent, YoutubeFeedComponent
// MenuComponent,
// WGamesComponent,
} from "components";
import BeAGuestComponent from "components/new-home-page/BeAGuestComponent";
import { useRouter } from "next/router";
import FeaturedCreatorsComponent from "components/new-home-page/FeaturedCreatorsComponent";
import { Fade } from "react-awesome-reveal";
// import dynamic from "next/dynamic";
import Head from "next/head";
import styled from "styled-components";
import { Seven, W } from "layout/HeaderComponent/components/icons";
import ArrowDownIcon from "../../public/images/icons/arrow-down.svg";
import Link from "next/link";
// -------------
import { MenuComponent } from "components/new-home-page/MenuComponent";
import TotalGivenAwayComponent from "components/new-home-page/TotalGivenAwayComponent";
import { WGamesComponent } from "components/new-home-page/WGamesComponent";
import HomeLoginComponent from "components/new-home-page/HomeLoginComponent";

// const StartEarningRewardsSectionElement = dynamic(
//   () => import("components/ScreenComponent/StartEarningRewards/StartEarningRewardsSection"),
//   {
//     ssr: false,
//   }
// );

var Main = function Main(_ref) {
  var highlights = _ref.highlights,
    latest = _ref.latest;
  var router = useRouter();
  if (router.isFallback) {
    return __jsx(React.Fragment, null, "Loading...");
  }
  return __jsx(React.Fragment, null, __jsx(Head, null, __jsx("title", null, "WRewards"), __jsx("meta", {
    name: "google-site-verification",
    content: "QYkqzD1YpIG2hlghvwwa2EAPZRrWBW0vpSRm-rM28gI"
  })), __jsx(HomeLoginComponent, null), __jsx(Fade, {
    triggerOnce: true,
    direction: "right"
  }, __jsx(TotalGivenAwayComponent, {
    id: "TotalGivenAway",
    nextScrollHref: "#BeAGuest"
  })), __jsx(MenuComponent, null), __jsx(ComponentTitleBlock, {
    className: "container"
  }, __jsx(ComponentTitle, null, __jsx(Seven, {
    fill: "var(--pure-sky)"
  }), "Free Slots"), __jsx(ComponentTitleLink, {
    href: "/games/slots"
  }, "Show all", __jsx(ArrowDownIcon, null))), __jsx(SlotsComponent, {
    id: "slots"
  }), __jsx(ComponentTitleBlock, {
    className: "container"
  }, __jsx(ComponentTitle, null, __jsx(W, {
    fill: "var(--pure-sky)"
  }), "WRewards Originals"), __jsx(ComponentTitleLink, {
    href: "/games"
  }, "Show all", __jsx(ArrowDownIcon, null))), __jsx(WGamesComponent, {
    id: "w-games"
  }), __jsx(Fade, {
    triggerOnce: true,
    direction: "left"
  }, __jsx(FeaturedCreatorsComponent, {
    id: "FeaturedCreators"
  })), __jsx(Fade, {
    triggerOnce: true,
    direction: "right"
  }, __jsx(YoutubeFeedComponent, {
    id: "YoutubeFeed",
    highlights: highlights,
    latest: latest
  })), __jsx(Fade, {
    triggerOnce: true,
    direction: "left"
  }, __jsx(BeAGuestComponent, {
    id: "BeAGuest",
    nextScrollHref: "#LeaderboardWinnersScreen"
  })), __jsx(FrequentlyAskedQuestionsComponent, {
    id: "FrequentlyAskedQuestions"
  }));
};
export var __N_SSG = true;
export default _withSuperJSONPage(Main);

// export const getStaticPaths: GetStaticPaths = async () => {
// 	return {
// 		fallback: true, // false or "blocking"
// 		paths: [
// 			// {
// 			//   params: {
// 			//     name: 'next.js',
// 			//   },
// 			// }, // See the "paths" section below
// 		],
// 	}
// }

var ComponentTitleBlock = styled.div.withConfig({
  displayName: "pages__ComponentTitleBlock",
  componentId: "sc-112ub4d-0"
})(["display:flex;justify-content:space-between;align-items:center;padding:16px;font-family:var(--font-family-golos);font-weight:600;color:var(--daylight-color);"]);
var ComponentTitle = styled.h2.withConfig({
  displayName: "pages__ComponentTitle",
  componentId: "sc-112ub4d-1"
})(["display:flex;align-items:center;gap:8px;font-size:16px;margin:0;"]);
var ComponentTitleLink = styled(Link).withConfig({
  displayName: "pages__ComponentTitleLink",
  componentId: "sc-112ub4d-2"
})(["display:flex;align-items:center;gap:8px;font-size:16px;cursor:pointer;& path{fill:var(--pure-sky);}svg{transform:rotate(270deg) translateY(0) scale(1);transition:transform 0.3s;}&:hover{svg{transform:rotate(270deg) translateY(3px) scale(1.5);}}"]);