import React from "react";
import styled, { keyframes } from "styled-components";
import Image from "next/image";
import { TheButton, TheIcon } from "components/TheComponents";
import { useAppContext } from "contexts";

function HomeLoginComponent() {
  const { loginModalProps, profile } = useAppContext();

  return (
    <Container className="container">
      {profile?.displayName ? (
        <Header>
          <h2 className="title">
            {`Welcome, `}
            <span className="highlight">{profile?.displayName}</span>!
          </h2>
          <p className="subtitle">
            We hope you are having a greand and lucky day! Enjoy your WRewards!
          </p>
        </Header>
      ) : (
        <Content>
          <div className="animated">
            {[1, 2, 3, 4, 5, 6, 7, 8].map((item, key) => {
              return (
                <div className={`item item-${item}`} key={`${item}-${key}`}>
                  <Image src={`/images/HomeLoginComponent/${item}.png`} alt="" fill />
                </div>
              );
            })}
          </div>
          <div className="main-image">
            <Image
              src={"/images/HomeLoginComponent/main-logo-2.png"}
              fill
              alt=""
              style={{ objectFit: "contain", filter: "drop-shadow(#141722 0rem 0rem 2rem)" }}
            />
          </div>
          <p className="text">
            A VIP experience through personalized rewards, leaderboards, raffles & more! Earn your
            rewards on favorite casinos everyday!
          </p>
          <TheButton preset="violet" onClick={() => loginModalProps.onOpenLogin()}>
            <TheIcon icon="twitch:small:violet" size={16} />
            Log in via Twitch
          </TheButton>
        </Content>
      )}
    </Container>
  );
}

const Header = styled.div`
  margin-top: 4rem;

  .title {
    text-transform: uppercase;
    font-style: italic;
    font-family: var(--font-family-exo);
    font-weight: 900;
    color: #d5e0f5;
    font-size: 1.5rem;
  }

  .subtitle {
    font-family: var(--font-family-exo);
    font-weight: 400;
    color: #d5e0f5;
    font-size: 1.125rem;
    color: #abbadb;
  }

  .highlight {
    color: #00b2ff;
  }
`;

const Container = styled.div`
  margin: 1rem auto;
`;

const fall = keyframes`
    0% {
        transform: translateY(-100px);
        opacity: 1;
    }

    40% {
        transform: translateY(600px);
        opacity: 1;
    }
    41% {
        opacity: 0;
        transform: translateY(-100px) translateX(30px);
    }

    100% {
    transform: translateY(600px) translateX(30px);
    }
`;

const Content = styled.div`
  background: url("/images/HomeLoginComponent/section-bg.svg"), var(--Dark-Midnight, #141722);
  border-radius: 0.75rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  padding: 2rem;
  overflow: hidden;
  position: relative;

  .animated {
    position: absolute;
    inset: 0;

    .item {
      position: absolute;
      animation: ${fall} ease-in infinite;
    }

    .item-1 {
      width: 80px;
      height: 80px;
      left: 10%;
      animation-duration: 3.5s;
      animation-delay: 1s;
    }

    .item-2 {
      width: 50px;
      height: 50px;
      left: 15%;
      animation-duration: 3.5s;
      animation-delay: 0.5s;
    }

    .item-3 {
      width: 60px;
      height: 60px;
      left: 20%;
      animation-duration: 3.5s;
      animation-delay: 1.5s;
    }

    .item-4 {
      width: 120px;
      height: 120px;
      left: 5%;
      animation-duration: 3.5s;
      animation-delay: 2s;
    }

    .item-5 {
      width: 80px;
      height: 80px;
      right: 5%;
      animation-duration: 3.5s;
      animation-delay: 1s;
    }

    .item-6 {
      width: 50px;
      height: 50px;
      right: 25%;
      animation-duration: 3.5s;
      animation-delay: 0.5s;
    }

    .item-7 {
      width: 60px;
      height: 60px;
      right: 10%;
      animation-duration: 3.5s;
      animation-delay: 1.5s;
    }

    .item-8 {
      width: 120px;
      height: 120px;
      right: 15%;
      animation-duration: 3.5s;
      animation-delay: 2s;
    }
  }

  .main-image {
    position: relative;
    min-height: 130px;
    max-width: 500px;
    width: 100%;
  }

  .text {
    font-family: var(--font-family-golos);
    margin: 0;
    max-width: 500px;
    text-align: center;
    color: var(--daylight-daylight-75, rgba(200, 215, 245, 0.75));
  }
`;

//
export default HomeLoginComponent;
